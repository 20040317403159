import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Survey, { Loader } from '@clozd/surveys-ui';
import parseSurveyResponse from './utils/parseSurveyResponse.ts';

const App = () => {
	const [isSessionValid, setIsSessionValid] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [surveyDefinition, setSurveyDefinition] = useState({});
	const [feedbackChannel, setFeedbackChannel] = useState('buyer_survey');
	const [surveyType, setSurveyType] = useState('win_loss');
	const [dealContacts, setDealContacts] = useState([]);
	const [outcome, setOutcome] = useState('');
	const [error, setError] = useState(false);
	const [additionalSurveys, setAdditionalSurveys] = useState({});
	const [analyticsInfo, setAnalyticsInfo] = useState({});

	// reference for submitting response
	const [surveySessionId, setSurveySessionId] = useState('');
	const [distributionEventId, setDistributionEventId] = useState('');
	const [participant, setParticipant] = useState({});
	const [status, setStatus] = useState('');

	// reference for updating analytics 
	const [distributionEventTrackingId, setDistributionEventTrackingId] = useState('');

	const url = new URL(window.location);

	useEffect(() => {
		async function fetchSurveyDef() {
			const survey_session_id = url.pathname.split('/')[1];
			const distribution_event_id = url.pathname.split('/')[2];
			if (survey_session_id && distribution_event_id) {
				try {
					setSurveySessionId(survey_session_id);
					setDistributionEventId(distribution_event_id);
					const res = await axios.post(`/api/surveys/${survey_session_id}`, {
						distribution_event_id,
						feedback_channel: url.searchParams.get('rep_survey') ? 'rep_survey' : 'buyer_survey',
					});
					// For backwards compatibility for Surveys 2.0 changes ('attributes' column becomes 'settings')
					const survey_definition_snapshot = JSON.parse(JSON.stringify(res.data.data.survey_definition_snapshot).replaceAll('attributes', 'settings'));
					setSurveyDefinition(survey_definition_snapshot);
					setSurveyType(res.data.data.survey_type);
					setDistributionEventTrackingId(res.data.distributionEvent.distribution_event_tracking_id);
					if (res.data.data?.feedback_channel === 'rep_survey') {
						setFeedbackChannel('rep_survey');
						setOutcome(res.data.data?.attributes?.outcome === 'win' ? 'won' : 'loss');
						const dealContactsNoRedacts = res.data.data?.attributes?.deal_contacts?.filter(contact => {
							return contact.first_name && contact.primary_email;
						}) || [];
						setDealContacts(dealContactsNoRedacts);
						setAdditionalSurveys(res.data.additionalSurveys);
					} else {
						setOutcome(url.searchParams.get('outcome'));
					}
					setIsSessionValid(true);
					setParticipant(res.data.participant);

					if (res.data.data.survey_completed) {
						setStatus('responded');
					} else if (Date.parse(res.data.data.expiration_date) < Date.now()) {
						setStatus('expired');
					} else {
						setStatus('active');
					}

				} catch (err) {
					switch (err.response.data.errorCode) {
						// params are passed, but no session exists
						case 'SURV003':
							setStatus('err');
							break;
						default:
							setStatus('err');
					}
				}
			} else { // is empty (no param(s) passed)
				setIsSessionValid(false);
			}
			setIsLoading(false);
		}
		fetchSurveyDef();
	}, [url.pathname]);

	// Capture new survey state every 4 seconds
	let intervalId;
	useEffect(() => {
		intervalId = setInterval(sendSurveyState, 4000);
		return () => clearInterval(intervalId); // cleans up interval on unmount
	}, [analyticsInfo]);

	const sendSurveyState = async () => {
		if (Object.keys(analyticsInfo).length > 0) {
			await axios.post(`/api/surveys/${surveySessionId}/survey_analytics`, { survey_info: analyticsInfo });
		}
	};

	const startNewSurvey = (survey_session_id) => {
		setSurveySessionId(survey_session_id);
		window.location.href = `${window.location.origin}/${survey_session_id}/${distributionEventId}?rep_survey=true`;
	};

	const submitSurvey = async (surveySnapshot, repSurveyOutcome) => {
		// call to update survey engine (distribution) and clozd platform (add a Response and response_elements)
		try {
			const {
				outcome,
				vendor,
				vendorInput,
				response_elements,
				salesRepData,
			} = parseSurveyResponse(surveySnapshot, repSurveyOutcome, participant.participant_id);

			await axios.put(`/api/surveys/${surveySessionId}`, {
				outcome,
				vendor,
				vendorInput,
				response_elements,
				distribution_event_id: distributionEventId,
				feedback_channel: feedbackChannel,
				distribution_participant_history_id: participant?.distribution_participant_history_id,
				...(feedbackChannel === 'rep_survey' && salesRepData),
			});
		} catch (err) {
			setError(true);
			setStatus('err');
		}
	};

	const updateSurveyAnalytics = async (surveyInfo) => {
		const survey_info = {
			...surveyInfo,
			distribution_event_tracking_id: distributionEventTrackingId,
		};
		setAnalyticsInfo(survey_info);
	};

	const surveyStatus = (() => {
		if (isLoading) return 'isLoading';
		if (status === 'err' || status === 'expired' || !isSessionValid) return 'isUnavailable';
		if (status === 'responded') return 'isResponded';
		return null;
	})();

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Survey
			feedbackChannel={feedbackChannel}
			error={error}
			outcome={outcome}
			participantDetails={participant}
			submitSurvey={submitSurvey}
			surveyDefinition={surveyDefinition}
			surveyType={surveyType}
			surveyStatus={surveyStatus}
			updateSurveyAnalytics={updateSurveyAnalytics}
			additionalSurveys={additionalSurveys}
			contacts={dealContacts}
			startNewSurvey={startNewSurvey}
		/>
	);
};

export default App;
