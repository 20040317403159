import isEmailValid from './emailValidator.ts';

export interface SelectedDriverWithQuote {
	driverId: string;
	quoteId: string;
}

export interface CustomQuestionResponse {
	type: string;
	answer: string;
	question: string;
	question_id: string;
	data_type?: string;
	metadata_definition_id?: string;
}

interface Contact {
	first_name: string;
	last_name?: string;
	primary_email: string;
}

interface ParsedSurveyResponse {
	outcome: string | null;
	response_elements: (SelectedDriverWithQuote | CustomQuestionResponse)[],
	salesRepData?: {
		contacts: Contact[],
		attributes: {
			contact_collection_only?: boolean;
			exclude_details?: {
				exclude_reason: string;
				sales_rep_participant_id: string;
			}
		}
	},
	vendor: string;
	vendorInput: string | null;
}

/**
 * @param {*} surveySnapshot - array of pages from survey
 * @returns ParsedSurveyResponse object with survey elements that Clozd platform responseConsumer can use
 */
function parseSurveyResponse(surveySnapshot, repSurveyOutcome: string = 'unknown', participantId: string): ParsedSurveyResponse {
	// repSurveyOutcome could potentially be "loss" with new rep surveys
	const validOutcomes: string[] = ['won', 'lost to no decision', 'lost to competitor', 'unknown'];
	const _repSurveyOutcome: string = validOutcomes.includes(repSurveyOutcome) ? repSurveyOutcome : 'unknown';
	const customQuestionResponses: CustomQuestionResponse[] = [];
	let selectedDriversWithQuotes: SelectedDriverWithQuote[] = [];
	let onlyRepContactCollection: boolean = false;

	// default values
	const parsedResponse: ParsedSurveyResponse = {
		outcome: _repSurveyOutcome, // rep surveys with no outcome question will have an "unknown" response decision if the deal was a Loss
		vendor: 'none',
		vendorInput: null,
		response_elements: [],
	};

	// If there is only the intro, contacts, and end_screen (3 total elements), it is only contact collection.
	if (surveySnapshot.length === 3 && !!surveySnapshot.find(page => page.page_elements[0].type === 'contacts')) {
		onlyRepContactCollection = true;
	}

	surveySnapshot.forEach((page) => {
		const { type, value, settings, ...element } = page.page_elements[0];

		switch (type) {
			case 'outcome':
				parsedResponse.outcome = value;
				break;
			case 'competitors':
				if (!value) { // if competitors is not required and the question is bypassed
					parsedResponse.vendor = 'none';
				}

				if (['none', 'unknown'].includes(value)) {
					parsedResponse.vendor = value;
					break;
				}

				// eslint-disable-next-line no-case-declarations
				const existingCompetitor = settings?.competitors?.find((comp) => comp.competitor_id === value || comp.name === value);
				if (existingCompetitor) {
					// the response consumer in Clozd Platform expects a stringified object for the vendor
					parsedResponse.vendor = JSON.stringify({
						competitor_id: existingCompetitor.competitor_id,
						name: existingCompetitor.name,
					});
				} else { // was a newly added competitor
					parsedResponse.vendorInput = value;
				}
				break;
			case 'drivers':
				selectedDriversWithQuotes = value;
				break;
			case 'contacts':
				// eslint-disable-next-line no-case-declarations
				let contacts = value?.contacts;
				// remove any contacts that are missing a first_name, last_name and valid primary_email
				contacts = contacts.filter(contact => {
					return contact.first_name && contact.last_name && isEmailValid(contact.primary_email);
				});
				parsedResponse.salesRepData = {
					contacts,
					attributes: {
						contact_collection_only: onlyRepContactCollection,
						...(value?.exclude_details && {
							exclude_details: {
								exclude_reason: value.exclude_details.exclude_reason,
								sales_rep_participant_id: participantId,
							},
						}),
					},
				};
				break;
			case 'question':
				if (value || (Array.isArray(value) && value.length)) { // may not be required
					let answer = value;
					if (settings.data_type === 'picklist' && settings.picklist_type === 'multi-select') {
						answer = value.map((v, index) => ({
							id: index,
							name: v,
						}));
					}
					if (['date', 'datetime'].includes(settings.data_type)) {
						answer = new Date(answer).toISOString();
					}
					// if (settings.data_type === 'datetime') { // TODO - fix later for timezones
					// 	if (Object.values(answer).every(value => !value)) {
					// 		break;
					// 	}
					// }
					customQuestionResponses.push({
						type,
						answer,
						question: element.question_text,
						data_type: settings.data_type,
						question_id: element.survey_element_id,
						metadata_definition_id: settings.metadata_definition_id,
					});
				}
				break;
			default:
				break;
		}
	});

	// combine the drivers and custom questions to create response_elements
	parsedResponse.response_elements = Object.values(selectedDriversWithQuotes);
	if (customQuestionResponses?.length) {
		parsedResponse.response_elements = [...selectedDriversWithQuotes, ...customQuestionResponses];
	}

	return parsedResponse;
}

export default parseSurveyResponse;